






















import {Component, Prop, Watch, Vue, Emit} from 'vue-property-decorator'
import {QrcodeStream} from 'vue-qrcode-reader'
import {Order} from '~/components/data-class/data-class'

@Component({
    components: {
        QrcodeStream
    }
})
export default class WaybillScannerDialog extends Vue {
    @Prop({default: false})
    visible!: boolean

    @Prop()
    order: Order

    set dialogVisible(v) {
        this.$emit('update:visible', v)
    }

    get dialogVisible() {
        return this.visible
    }

    input: string = ''

    @Watch('order', {deep: true})
    vChanged() {
        this.input = this.order.waybill_number
    }

    inputEnter() {
        this.$emit('code-scanned', this.input)
        this.dialogVisible = false
    }

    codeScanned(decodedString: string) {
        this.$message.info(decodedString)
        this.dialogVisible = false

        let awbNo = ''
        const prefix = [
            'https://ucmp-x.sf-express.com/xaccess/scan/order?reserve=',
            'http://ucmp-x.sf-express.com/xaccess/scan/order?reserve='
        ]

        for (const p of prefix) {
            if (decodedString.startsWith(p))
                awbNo = decodedString.replace(p, '')
        }

        if (awbNo.length) {
            this.$emit('code-scanned', awbNo)
        } else {
            this.$message.error('NOT SUPPORT NON SF-EXPRESS QR-CODE')
        }
    }
}

